import BaseTextComponent from "@/components/base/el/BaseTextComponent";
export default {
    name: "BaseA",
    mixins: [BaseTextComponent],
    data() {
        return {
            tag: 'a',
            is_nav_link: false
        }
    },
    created() {
      if (this.component_data.attr && this.component_data.attr.class && this.component_data.attr.class.includes('nav-link')) {
        this.is_nav_link = true
      }
    }
}
